body{
    background-image: url("/public/img/bg-login.jpg");
}
.mainDiv{
    width: 30%;
    height: 30vh;
    background-color: rgba(0,0,0,0.5);
    text-align: center;
    padding: 10px;
    position: fixed;
    top:50%;
    left: 50%;
    margin-top: -25vh;
    margin-left: -15%;
    color: white;
    border-radius: 6px;
}